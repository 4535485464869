import {
  colorBlueMedium,
  colorSteelDarker,
  colorSteelLighter,
  colorWhite,
} from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { FunctionComponent } from "react";
import { useCallback, useEffect, useState } from "react";

import Icon from "../Icon";

interface Props {
  top?: number;
}

const ScrollTopButton: FunctionComponent<Props> = ({ top = 1 }) => {
  const [visible, setVisible] = useState(false);

  const onScroll = useCallback(() => {
    const scrollTop =
      typeof document !== "undefined"
        ? document?.documentElement?.scrollTop
        : 0;

    setVisible(scrollTop > top);
  }, [top]);

  const scrollToTop = () => {
    window.scrollTo({
      behavior: "smooth",
      top: 0,
    });
  };

  useEffect(() => {
    document.addEventListener("scroll", onScroll);

    // Remove listener on unmount
    return () => document.removeEventListener("scroll", onScroll);
  }, [onScroll]);

  return (
    <button
      aria-label={"scroll to top"}
      css={css`
        background: ${colorWhite};
        border: solid 1px ${colorSteelLighter};
        border-radius: 100%;
        bottom: 1.5rem;
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.06);
        color: ${colorSteelDarker};
        height: 4rem;
        padding: 0;
        position: fixed;
        opacity: ${visible ? 1 : 0};
        right: 1.5rem;
        transform: ${visible ? "translateY(0)" : "translateY(0.5rem)"};
        transition: border-color 0.225s ease-out, box-shadow 0.225s ease-out,
          color 0.225s ease-out, opacity 0.3s ease-out, transform 0.3s ease-out;
        width: 4rem;

        :hover {
          border-color: rgba(0, 113, 217, 0.4);
          box-shadow: 0px 4px 8px rgba(68, 89, 121, 0.12);
          color: ${colorBlueMedium};
          cursor: pointer;
        }
      `}
      onClick={scrollToTop}
    >
      <Icon color={"inherit"} size={"17px"} source={"arrow-up"} yPos={"1px"} />
    </button>
  );
};

export default ScrollTopButton;
