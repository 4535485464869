import { getPage } from "@10xdev/cms/resolvers/get-page";
import PageLayout from "@10xdev/ui/src/PageLayout";
import ISO31661 from "iso-3166-1";
import type { GetServerSideProps, NextPage } from "next";

import { stripUndefineds } from "../utils";

interface Props {
  data: any;
  staticProps: {
    countryCode: string;
    showLanguageSelect: boolean;
  };
}

const Home: NextPage<Props> = ({ data }) => <PageLayout data={data} />;

export const getServerSideProps: GetServerSideProps = async ({
  locale,
  req,
}) => {
  const countryCode = req.headers["cf-ipcountry"];

  const showLanguageSelect =
    countryCode === ISO31661.whereCountry("China")?.alpha2 ||
    countryCode === ISO31661.whereCountry("Japan")?.alpha2;

  const data = await getPage("home-page", locale!);

  return {
    props: stripUndefineds({
      data,
      staticProps: {
        countryCode,
        showLanguageSelect,
      },
    }),
  };
};

export default Home;
